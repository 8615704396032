
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  apiGetlang,
  translateText,
  langCodeSave,
  langCodeDetail,
} from "@/api/setting";
import { throttle } from "@/utils/util";
@Component
export default class SetMealEdit extends Vue {
  identity: any = null; // 当前编辑用户的身份ID
  code: any = null;
  // 添加商城表单数据
  form: any = {
    text: "", // 语句
    list: [],
  };
  allLang: any = [];

  mealModule = {
    marketing: [],
    apply: [],
  };
  // 表单校验
  rules = {
    text: [{ required: true, message: "必填项不能为空", trigger: "blur" }],
  };

  loading = false;

  // 获取语言列表
  getLangList() {
    apiGetlang({ status: 1 })
      .then((res: any) => {
        this.allLang = res;
      })
      .catch(() => {
        this.$message.error("获取数据失败，请重试！");
      });
  }

  // 点击表单提交
  onSubmit(formName: string) {
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement;
    refs.validate((valid: boolean): any => {
      if (!valid) return;

      if (!this.identity) {
        this.handleSetMealAdd();
      } else {
        this.handleSetMealEdit();
      }
    });
  }
  // 翻译语句
  async onTranslate() {
    var that = this;
    this.loading = true;
    try {
      const res: any = await translateText({ text: this.form.text });
      this.loading = false;
      this.form.list = res.data.map(function (item: any) {
        return {
          type_id: item.type_id,
          lang_explain: item.translation,
          code: that.form.text,
          remarks: "",
        };
      });
    } catch (err) {
      this.loading = false;
    }
  }
  // 添加语句
  async handleSetMealAdd(): Promise<void> {
    await langCodeSave({ ...this.form });
    this.$message.success("添加成功");
    setTimeout(() => this.$router.go(-1), 500);
  }

  // 编辑语言
  async handleSetMealEdit(): Promise<void> {
    await langCodeSave({ ...this.form, id: this.identity });
    setTimeout(() => this.$router.go(-1), 500);
  }

  // 获取详情
  async getSetMealDetailFunc(): Promise<void> {
    const res: any = await langCodeDetail({
      code: this.code,
    });
    // Object.keys(res).map((key) => {
    //   if (res[key] === null || res[key] === undefined) return;
    //   this.$set(this.form, key, res[key]);
    // });
    // console.log(this.form);
    this.form.list = res.data;
  }

  async created() {
    this.getLangList();
    const query: any = this.$route.query;
    // 编辑模式：初始化数据
    this.identity = query.id;
    this.code = query.code;
    this.form.text = query.code;
    if (query.id) {
      await this.getSetMealDetailFunc();
    }
    this.onSubmit = throttle(this.onSubmit, 1000);
  }
}
